<template>
  <a-spin :spinning="loading">
    <div class="jump-button" @click="($event) => handleJump($event, 'all')">
      学员作业（
      <div class="flex warn-color bold">
        <a-tooltip>
          <template slot="title">上周已完课未完成作业数量</template>
          <span class="mr-8 hover-underline" @click="($event) => handleJump($event, 'prev')">{{
            report.lastWeekUncompletedHomeWorkCount
          }}</span>
        </a-tooltip>
        <a-tooltip>
          <template slot="title">本周已完课未完成作业数量</template>
          <span class="hover-underline" @click="($event) => handleJump($event, 'curr')">{{
            report.nowWeekUncompletedHomeWorkCount
          }}</span>
        </a-tooltip>
      </div>
      ）
      <a-icon type="right" class="mr-8" />
    </div>
  </a-spin>
</template>
<script>
import { getIndexHomeworkApi } from '@/api/home';
import queryString from 'query-string';
import storage from 'store';

export default {
  data() {
    return {
      report: {},
      loading: false,
      userInfo: storage.get('userInfo'),
    };
  },

  async created() {
    this.handleGetIndexHomeworkApi();
  },

  methods: {
    async handleGetIndexHomeworkApi() {
      this.loading = true;
      const { data } = await getIndexHomeworkApi();
      this.report = data;
      this.loading = false;
    },
    handleJump(e, type) {
      e.stopPropagation();

      const argsMap = {
        prev: {
          startTime: this.$moment().startOf('isoWeek').subtract(1, 'weeks').format('YYYY-MM-DD HH:mm'),
          endTime: this.$moment().startOf('isoWeek').subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD HH:mm'),
          status: 'NO_COMPLETE',
          subject: this.userInfo.subject,
        },
        curr: {
          startTime: this.$moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm'),
          endTime: this.$moment().endOf('isoWeek').format('YYYY-MM-DD HH:mm'),
          status: 'NO_COMPLETE',
          subject: this.userInfo.subject,
        },
        all: {},
      };

      const str = queryString.stringify(argsMap[type]);

      this.$router.push(`/homeIndex/homeWorkList?${str}`);
    },
  },
};
</script>
<style scoped lang="less">
/deep/.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}

.jump-button {
  border: 1px solid rgba(31, 45, 61, 0.1);
  padding: 4px 0;
  width: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
}
</style>
